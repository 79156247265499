<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >CUMPLEAÑEROS</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">CUMPLEAÑOS</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>LISTADO DE CUMPLEAÑEROS</strong>
      </span>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4">
          <span><strong>CUMPLEAÑEROS MES: </strong></span>
          <div class="p-inputgroup">
            <Dropdown
              v-model="mes_cumpleanios"
              :options="meses"
              optionLabel="label"
              placeholder="Seleccione un mes"
              :filter="true"
              @change="BuscarFiltro()"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <span>&nbsp;</span>
          <div class="p-inputgroup">
            <span style="font-weight: bold; font-size: 1.2rem"
              ><strong>CUMPLEAÑOS HOY</strong></span
            >
            <Checkbox
              :disabled="enviando"
              v-model="hoy"
              :binary="true"
              v-tooltip.top="'Cumpleaños de hoy'"
              @change="cambiarHoy()"
            />
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <span>&nbsp;</span>
          <div class="p-inputgroup">
            <span style="font-weight: bold; font-size: 1.2rem"
              ><strong>CUMPLEAÑOS MAÑANA</strong></span
            >
            <Checkbox
              :disabled="enviando"
              v-model="manana"
              :binary="true"
              v-tooltip.top="'Cumpleaños del mes'"
              @change="cambiarManana()"
            />
          </div>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="BUSCAR"
            icon="pi pi-search"
            class="p-button-info"
            v-tooltip.top="'Buscar'"
            :loading="enviando"
            @click="BuscarFiltro()"
          />
        </div>
      </div>
    </div>
    <div class="mt-2">
      <DataTable
        ref="reporte_cumpleanios"
        key="id"
        :value="cumpleanios"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar..."
              />
            </span>
            <Button
              v-if="hoy"
              label="ENVIAR FELICITACIÓN"
              icon="pi pi-whatsapp"
              class="p-button-success"
              v-tooltip.top="'Enviar mensaje de WhatsApp'"
              @click="enviarWhatsapp()"
            />
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span
            class="flex align-items-center justify-content-center"
            style="
              background-color: #f8d7da;
              padding: 5px;
              border-radius: 5px;
              color: #721c24;
            "
          >
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column class="text-right" field="id" header="CÓD" :sortable="true">
        </Column>
        <Column field="nombre" header="CLIENTE" style="font-weight: bold">
        </Column>
        <Column field="telefono" header="TELÉFONO"> </Column>
        <Column field="fecha_nacimiento" header="EDAD ACTUAL">
          <template #body="{ data }">
            {{ edadMesesCliente(data.fecha_nacimiento) }}
          </template>
        </Column>
        <Column field="fecha_nacimiento" header="CUMPLEAÑOS">
          <template #body="{ data }">
            <div>
              <span
                style="font-weight: bold; font-size: 1.2rem"
                v-if="hoy || data.esta_de_cumple"
              >
                {{ formatFecha(data.fecha_nacimiento) }}
                <Badge value="HOY" class="p-badge-warning"></Badge>
              </span>
              <span
                style="font-weight: bold; font-size: 1.2rem"
                v-else-if="manana || data.cumple_manana"
              >
                {{ formatFecha(data.fecha_nacimiento) }}
                <Badge value="MAÑANA" class="p-badge-info"></Badge>
              </span>
              <span v-else style="font-weight: bold; font-size: 1.2rem">
                {{ formatFecha(data.fecha_nacimiento) }}</span
              >
            </div>
          </template>
        </Column>

        <Column field="nombre_municipio" header="PROVINCIA"> </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ClienteService from "@/service/ClienteService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      meses: [
        { label: "ENERO", value: 1 },
        { label: "FEBRERO", value: 2 },
        { label: "MARZO", value: 3 },
        { label: "ABRIL", value: 4 },
        { label: "MAYO", value: 5 },
        { label: "JUNIO", value: 6 },
        { label: "JULIO", value: 7 },
        { label: "AGOSTO", value: 8 },
        { label: "SEPTIEMBRE", value: 9 },
        { label: "OCTUBRE", value: 10 },
        { label: "NOVIEMBRE", value: 11 },
        { label: "DICIEMBRE", value: 12 },
      ],
      mes_cumpleanios: null,
      enviando: false,
      cumpleanios: [],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      hoy: false,
      manana: false,
      esta_de_cumple_hoy: false,
      esta_de_cumple_manana: false,
    };
  },
  clienteService: null,
  auth: null,
  mounted() {
    this.mes_cumpleanios = this.meses[new Date().getMonth()];
  },
  created() {
    this.clienteService = new ClienteService();
    this.auth = useAuth();
  },

  methods: {
    enviarWhatsapp() {
      let datos = {
        cumpleanios: this.cumpleanios,
      };
      this.clienteService
        .enviarFelicitationWhatsapp(datos)
        .then((data) => {
          if (data.success == false) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: data.message,
              life: 5000,
            });
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: data.message,
              life: 5000,
            });
            if (data.no_enviados.length > 0) {
              this.$toast.add({
                severity: "warn",
                summary: "Advertencia",
                detail:
                  "Los siguientes clientes no tienen número de teléfono: " +
                  data.no_enviados.join(", "),
                life: 20000,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatFecha(fecha) {
      if (!fecha) return "";
      let partesFecha = fecha.split("-");
      let fecha_nacimiento = new Date(
        Date.UTC(partesFecha[0], partesFecha[1] - 1, partesFecha[2])
      );
      let dia = fecha_nacimiento.getUTCDate(); // Obtener el día en UTC
      let mes_texto = this.mes_texto(fecha_nacimiento.getUTCMonth()); // Obtener el mes en UTC
      return dia + " de " + mes_texto;
    },

    cambiarHoy() {
      if (this.hoy) {
        this.manana = false;
        this.mes_cumpleanios = null;
        this.BuscarFiltro();
      }
    },
    cambiarManana() {
      if (this.manana) {
        this.hoy = false;
        this.mes_cumpleanios = null;
        this.BuscarFiltro();
      }
    },
    edadMesesCliente(fecha) {
      let fecha_nacimiento = new Date(fecha);
      let fecha_actual = new Date();
      let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
      let meses = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
      if (
        meses < 0 ||
        (meses === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
      ) {
        edad--;
        meses += 12;
      }
      return edad + " AÑOS Y " + meses + " MESES";
    },

    mes_texto(mes) {
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      return meses[mes];
    },

    BuscarFiltro() {
      this.cumpleanios = [];
      this.enviando = true;
      this.cargarReporteCumpleanios();
    },

    cargarReporteCumpleanios() {
      let datos = {
        mes: this.mes_cumpleanios ? this.mes_cumpleanios.value : null,
        hoy: this.hoy,
        manana: this.manana,
      };
      this.clienteService
        .filtrarCumpleanios(datos)
        .then((data) => {
          if (this.hoy) {
            this.cumpleanios = data.cumpleanios_hoy;
          } else if (this.manana) {
            this.cumpleanios = data.cumpleanios_manana;
          } else {
            this.cumpleanios = data.cumpleanios;
          }
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 10px;
}
</style>
